import { LocationByQuery } from "./subscription";

export const Prefixes = {
  milestone: "shipment.milestone.",
  reason: "shipment.milestone.reason.",
  containerMilestone: "container.milestone.",
};

export default Prefixes;

export enum ShipmentTransitStatus {
  PLANNED = "PLANNED",
  IN_TRANSIT = "IN_TRANSIT",
  COMPLETED = "COMPLETED",
}

export enum ShipmentMode {
  RAIL = "RAIL",
  AIR = "AIR",
  OCEAN = "OCEAN",
  ROAD = "ROAD",
  INLAND_WATERWAY = "INLAND_WATERWAY",
  CUSTOMS = "CUSTOMS",
  INLAND_WATER = "INLANDWATER",
  MULTIMODAL = "MULTIMODAL",
  UNKNOWN = "UNKNOWN",
}

export enum DelayStatus {
  ON_TIME = "ON_TIME",
  DELAYED = "DELAYED",
}

export enum SortableField {
  PICKUP_COMBINED = "PICKUP_COMBINED",
  DEPARTURE_COMBINED = "DEPARTURE_COMBINED",
  ARRIVAL_COMBINED = "ARRIVAL_COMBINED",
  DELIVERY_COMBINED = "DELIVERY_COMBINED",
  LAST_CHANGED = "LAST_CHANGED",
}

export interface ShipmentsListDateFilter {
  from: string;
  to: string;
}

export interface ShipmentsCurrentPositionFilter {
  type: string;
  key: string;
}

export interface SortedByMilestone {
  field: string;
  order: string;
}

export interface MilestonesDateRangeFilters {
  arrivalCombined?: ShipmentsListDateFilter;
  deliveryCombined?: ShipmentsListDateFilter;
  departureCombined?: ShipmentsListDateFilter;
  pickupCombined?: ShipmentsListDateFilter;
  importCustomsCleared?: ShipmentsListDateFilter;
  exportCustomsCleared?: ShipmentsListDateFilter;
}

export enum ShipmentProducts {
  FREIGHT_EURO_CONNECT_INTERNATIONAL = "FREIGHT_EURO_CONNECT_INTERNATIONAL",
  FREIGHT_EURO_CONNECT_DOMESTIC = "FREIGHT_EURO_CONNECT_DOMESTIC",
  FREIGHT_EURAPID_INTERNATIONAL = "FREIGHT_EURAPID_INTERNATIONAL",
  FREIGHT_EURAPID_DOMESTIC = "FREIGHT_EURAPID_DOMESTIC",
  FREIGHT_EURO_LINE_INTERNATIONAL = "FREIGHT_EURO_LINE_INTERNATIONAL",
  FREIGHT_EURO_LINE_DOMESTIC = "FREIGHT_EURO_LINE_DOMESTIC",
  FREIGHT_EURO_NET_INTERNATIONAL = "FREIGHT_EURO_NET_INTERNATIONAL",
  FREIGHT_EURO_NET_DOMESTIC = "FREIGHT_EURO_NET_DOMESTIC",
  FREIGHT_COLDCHAIN = "FREIGHT_COLDCHAIN",
  FREIGHT_TRADE_FAIRS_EVENTS = "FREIGHT_TRADE_FAIRS_EVENTS",
  FREIGHT_STANDARD_PALLET_DOMESTIC = "FREIGHT_STANDARD_PALLET_DOMESTIC",
  FREIGHT_PREMIUM_PALLET_DOMESTIC = "FREIGHT_PREMIUM_PALLET_DOMESTIC",
  FREIGHT_LEAD_LOGISTICS_PROVIDER = "FREIGHT_LEAD_LOGISTICS_PROVIDER",
  FREIGHT_TRANSPORT_RELATED_WAREHOUSING = "FREIGHT_TRANSPORT_RELATED_WAREHOUSING",
  AIRFREIGHT_PLUS_PRIORITY = "AIRFREIGHT_PLUS_PRIORITY",
  AIRFREIGHT_PLUS_ECONOMY = "AIRFREIGHT_PLUS_ECONOMY",
  AIRFREIGHT_PLUS_CONNECT = "AIRFREIGHT_PLUS_CONNECT",
  AIR_PRIORITY = "AIR_PRIORITY",
  AIR_ECONOMY = "AIR_ECONOMY",
  AIR_CONNECT_CONSOL = "AIR_CONNECT_CONSOL",
  AIR_CONNECT_DIRECT = "AIR_CONNECT_DIRECT",
  AIR_CONNECT_BACK_TO_BACK = "AIR_CONNECT_BACK_TO_BACK",
  AIR_CONNECT_MAIN_DECK = "AIR_CONNECT_MAIN_DECK",
  AIR_TEMPERATURE_CONTROLLED = "AIR_TEMPERATURE_CONTROLLED",
  AIR_TAILORED_LIFT = "AIR_TAILORED_LIFT",
  AIR_SAMEDAY = "AIR_SAMEDAY",
  AIR_VIEW = "AIR_VIEW",
  AIR_SECURE = "AIR_SECURE",
  AIR_AEROSPACE = "AIR_AEROSPACE",
  OCEAN_LESS_THAN_CONTAINER_LOAD = "OCEAN_LESS_THAN_CONTAINER_LOAD",
  OCEAN_FULL_CONTAINER_LOAD = "OCEAN_FULL_CONTAINER_LOAD",
  OCEAN_BULK = "OCEAN_BULK",
  OCEAN_BUYERS_AND_SHIPPERS_CONSOLIDATION = "OCEAN_BUYERS_AND_SHIPPERS_CONSOLIDATION",
  OCEAN_CHARTER = "OCEAN_CHARTER",
  OCEAN_TEMPERATURE_CONTROLLED_LOAD = "OCEAN_TEMPERATURE_CONTROLLED_LOAD",
  OCEAN_REEFER_LOGISTICS = "OCEAN_REEFER_LOGISTICS",
  OCEAN_SECURE = "OCEAN_SECURE",
  OCEAN_NON_CONTAINERIZED_LOAD = "OCEAN_NON_CONTAINERIZED_LOAD",
  FLEXITANKS = "FLEXITANKS",
  EXPRESS = "EXPRESS",
  CUSTOMS_SERVICES = "CUSTOMS_SERVICES",
  RAIL_LESS_THAN_CONTAINER_LOAD = "RAIL_LESS_THAN_CONTAINER_LOAD",
  RAIL_FULL_CONTAINER_LOAD = "RAIL_FULL_CONTAINER_LOAD",
  PARCEL_INTERNATIONAL = "PARCEL_INTERNATIONAL",
  PARCEL_DOMESTIC = "PARCEL_DOMESTIC",
  HOME_DELIVERY = "HOME_DELIVERY",
  MILITARY_LOGISTICS = "MILITARY_LOGISTICS",
}

export interface ShipmentsListFilters extends MilestonesDateRangeFilters {
  order: SortedByMilestone[];
  query?: string;
  favorite?: boolean;
  delayStatus?: DelayStatus;
  transitStatus?: ShipmentTransitStatus[];
  transportMode?: ShipmentMode[];
  productName?: ShipmentProducts[];
  origin?: LocationByQuery;
  destination?: LocationByQuery;
  hasIrregularity?: boolean;
  hasCustomsDeclaration?: boolean;
  limit: number;
  offset: number;
  shipperCompanyName?: string;
  consigneeCompanyName?: string;
  customerCompanyName?: string;
}

export interface ShipmentsListRequestBody
  extends Omit<ShipmentsListFilters, "origin" | "destination"> {
  origin?: string[];
  destination?: string[];
  currentPosition?: ShipmentsCurrentPositionFilter;
}

interface ShipmentsListPagination {
  count: number;
  limit: number;
  offset: number;
  totalCount: number;
  totalRetrievableCount: number;
}

interface ShipmentReferences {
  shipperReferences?: string[];
  consigneeReferences?: string[];
  customerReferences?: string[];
  containerNumbers?: string[];
  flightVoyageNumbers?: string[];
  movementReferenceNumbers?: string[];
}

export enum IrregularityCode {
  MISSED_CUT_OFF_TIME = "MISSED_CUT_OFF_TIME",
  DOCUMENTATION = "DOCUMENTATION",
  PICKUP_OR_DELIVERY_TRUCKING = "PICKUP_OR_DELIVERY_TRUCKING",
  SHIPMENT_ON_HOLD = "SHIPMENT_ON_HOLD",
  CUSTOMS_PROCESS = "CUSTOMS_PROCESS",
  GOVERNMENT_OR_LEGAL_ISSUES = "GOVERNMENT_OR_LEGAL_ISSUES",
  CARRIER_SERVICE_DELAY = "CARRIER_SERVICE_DELAY",
  SPLIT_SHIPMENT = "SPLIT_SHIPMENT",
  MISSED_CONNECTION = "MISSED_CONNECTION",
  MISROUTE = "MISROUTE",
  WAREHOUSE_EXPORT_OR_IMPORT = "WAREHOUSE_EXPORT_OR_IMPORT",
  GOODS_OR_PACKAGING_SPECIAL_HANDLING = "GOODS_OR_PACKAGING_SPECIAL_HANDLING",
  WEATHER = "WEATHER",
  DAMAGE_MAJOR = "DAMAGE_MAJOR",
  FORCE_MAJEURE = "FORCE_MAJEURE",
  BOOKING = "BOOKING",
  MISSING_OR_SHORT = "MISSING_OR_SHORT",
  INVOICING = "INVOICING",
  MESSAGING_OR_VISIBILITY = "MESSAGING_OR_VISIBILITY",
  DAMAGE_MINOR = "DAMAGE_MINOR",
  DAMAGE_MODERATE = "DAMAGE_MODERATE",
}

export enum IrregularityParty {
  AIRLINE = "AIRLINE",
  SHIPPER = "SHIPPER",
  CONSIGNEE = "CONSIGNEE",
  GOVERNMENT_OR_LOCAL_AUTHORITIES = "GOVERNMENT_OR_LOCAL_AUTHORITIES",
  DHL = "DHL",
  THIRD_PARTY = "THIRD_PARTY",
  NO_FAULT = "NO_FAULT",
}

export const IrregularitiesByRemark = {
  RED_SEA_DIVERSION: "Red Sea Diversion",
};

export interface ShipmentIrregularities {
  reasonCode: IrregularityCode;
  responsibleParty: IrregularityParty;
  remark: string;
  closedDate: string;
}

interface ShipmentLocation {
  countryCode: string;
  unloc: string;
  name: string;
}

interface ShipmentMilestoneDate {
  estimated: string;
  actual: string;
  combined: string;
}

export enum EventCode {
  ARR = "ARR",
  AAD = "AAD",
  AAP = "AAP",
  ONH = "ONH",
  CFM = "CFM",
  CLO = "CLO",
  CRE = "CRE",
  TBN = "TBN",
  COD = "COD",
  COS = "COS",
  CUC = "CUC",
  CUD = "CUD",
  CHE = "CHE",
  CHS = "CHS",
  GIT = "GIT",
  POD = "POD",
  ATD = "ATD",
  DEP = "DEP",
  DEW = "DEW",
  DBA = "DBA",
  DBD = "DBD",
  DFP = "DFP",
  DOS = "DOS",
  DOR = "DOR",
  ECP = "ECP",
  DHR = "DHR",
  ECR = "ECR",
  CEN = "CEN",
  DOC = "DOC",
  CAN = "CAN",
  ADD = "ADD",
  CAV = "CAV",
  FPR = "FPR",
  GIN = "GIN",
  GOU = "GOU",
  GLA = "GLA",
  GLR = "GLR",
  CLC = "CLC",
  IDC = "IDC",
  CUS = "CUS",
  INV = "INV",
  IRR = "IRR",
  ISFA = "ISFA",
  ISFS = "ISFS",
  LOF = "LOF",
  LOB = "LOB",
  ACC = "ACC",
  ORE = "ORE",
  OBA = "OBA",
  OBD = "OBD",
  OFD = "OFD",
  APT = "APT",
  PAR = "PAR",
  ATP = "ATP",
  GOT = "GOT",
  RFC = "RFC",
  REC = "REC",
  RCD = "RCD",
  REL = "REL",
  SHO = "SHO",
  ULB = "ULB",
  VGMA = "VGMA",
  VGME = "VGME",
  VGMS = "VGMS",
}

export interface ShipmentEvent {
  code: EventCode;
  actual: string;
  locationName: string;
}

export enum ProductServiceElements {
  CUSTOMER_DROP_OFF = "CUSTOMER_DROP_OFF",
  PRE_ADVISED_DELIVERY = "PRE_ADVISED_DELIVERY",
  CASH_ON_DELIVERY = "CASH_ON_DELIVERY",
  CARGO_INSURANCE = "CARGO_INSURANCE",
  DANGEROUS_GOODS = "DANGEROUS_GOODS",
  DELIVERY = "DELIVERY",
  DELIVERY_APPOINTMENT = "DELIVERY_APPOINTMENT",
  EXPRESS_DELIVERY_PRE_10 = "EXPRESS_DELIVERY_PRE_10",
  EXPRESS_DELIVERY_PRE_12 = "EXPRESS_DELIVERY_PRE_12",
  EXPRESS_NEXT_DAY = "EXPRESS_NEXT_DAY",
  FIXED_DELIVERY_DATE = "FIXED_DELIVERY_DATE",
  IMPORT_CUSTOMS = "IMPORT_CUSTOMS",
  EXPORT_CUSTOMS = "EXPORT_CUSTOMS",
  PICKUP = "PICKUP",
  PRE_ADVISED_PICKUP = "PRE_ADVISED_PICKUP",
  CUSTOMER_SELF_PICKUP = "CUSTOMER_SELF_PICKUP",
  PICKUP_APPOINTMENT = "PICKUP_APPOINTMENT",
}

export interface Shipment {
  id: string;
  key: string;
  housebill: string;
  transportMode: ShipmentMode;
  favorite?: boolean;
  partyTags?: string[];
  references?: ShipmentReferences;
  lastChanged?: string;
  productName?: ShipmentProducts;
  productServiceElements?: ProductServiceElements[];
  transitStatus?: ShipmentTransitStatus;
  delayStatus?: DelayStatus;
  delayDays?: number;
  irregularities?: ShipmentIrregularities[];
  shipperCompanyName?: string;
  consigneeCompanyName?: string;
  customerCompanyName?: string;
  deliverToCompanyName?: string;
  carrierCompanyNames?: string[];
  vesselNames?: string[];
  origin?: ShipmentLocation;
  originPort?: ShipmentLocation;
  destinationPort?: ShipmentLocation;
  destination?: ShipmentLocation;
  placeAcceptance?: ShipmentLocation;
  placeDelivery?: ShipmentLocation;
  pickup?: ShipmentMilestoneDate;
  departure?: ShipmentMilestoneDate;
  arrival?: ShipmentMilestoneDate;
  delivery?: ShipmentMilestoneDate;
  lastExportCustomsEvent?: ShipmentEvent;
  lastImportCustomsEvent?: ShipmentEvent;
  lastEvent?: ShipmentEvent;
  directShipment?: boolean;
}

export interface ShipmentsList {
  data?: Shipment[];
  paging?: ShipmentsListPagination;
  __typename: "ShipmentsList";
}

interface ShipmentDetailsReferences
  extends Pick<
    ShipmentReferences,
    "shipperReferences" | "consigneeReferences" | "customerReferences"
  > {
  commercialInvoiceNumbers?: string[];
}

enum PartyRole {
  BUYER = "buyer",
  CONSIGNEE = "consignee",
  DELIVER_TO = "deliverTo",
  MANUFACTURER = "manufacturer",
  NOTIFY = "notify",
  PICKUP_FROM = "pickupFrom",
  CUSTOMER = "customer",
  SHIPPER = "shipper",
  SUPPLIER = "supplier",
  IMPORTER = "importer",
  EXPORTER = "exporter",
}

interface Party {
  role: PartyRole;
  accountNumber: string;
  tags: string[];
}

export enum IncotermCode {
  EXW_OLD = "EXW_OLD",
  FOB_OLD = "FOB_OLD",
  FCA_OLD = "FCA_OLD",
  DAP_OLD = "DAP_OLD",
  DAT_OLD = "DAT_OLD",
  CIF_OLD = "CIF_OLD",
  CFR_OLD = "CFR_OLD",
  CIP_OLD = "CIP_OLD",
  CPT_OLD = "CPT_OLD",
  FCA = "FCA",
  EXW = "EXW",
  DAP = "DAP",
  CIP = "CIP",
  CFR = "CFR",
  DDP = "DDP",
  FAS = "FAS",
  FOB = "FOB",
  CPT = "CPT",
  CIF = "CIF",
  DPU = "DPU",
}

interface Incoterms {
  code: IncotermCode;
  place: string;
  name: string;
}

export enum WeightUnit {
  KGM = "KGM",
  LBR = "LBR",
  TNE = "TNE",
  LTN = "LTN",
  STN = "STN",
  ONZ = "ONZ",
}

interface Weight {
  unit: WeightUnit;
  value: number;
}

export enum VolumeUnit {
  CMQ = "CMQ",
  DMQ = "DMQ",
  MTQ = "MTQ",
  FTQ = "FTQ",
  INQ = "INQ",
  LTR = "LTR",
  GLL = "GLL",
}

interface Volume {
  unit: VolumeUnit;
  value: number;
}

export interface Measures {
  totalVolume?: Volume;
  totalWeight?: Weight;
  chargeableWeight?: Weight;
  pieces: number;
  loadingMeters: number;
}

export interface TransportUnitDetails {
  number: string;
  size: string;
}

export interface Emissions {
  totalCo2e: number;
  mainHaulEfficiency: number;
}

export enum EventType {
  SHIPMENT_EVENT = "SHIPMENT_EVENT",
  CONTAINER_EVENT = "CONTAINER_EVENT",
  CUSTOMS_EVENT = "CUSTOMS_EVENT",
  IRREGULARITY_EVENT = "IRREGULARITY_EVENT",
}

interface ContainerEvent {
  containerNumber: string;
  actual: string;
}

export interface Event {
  type: EventType;
  code: EventCode;
  actual: string;
  location?: ShipmentLocation;
  containers?: ContainerEvent[];
  irregularity?: ShipmentIrregularities;
}

export enum RouteType {
  CITY = "CITY",
  PORT = "PORT",
  AIRPORT = "AIRPORT",
  RAILSTATION = "RAILSTATION",
  ROADTERMINAL = "ROADTERMINAL",
  UNKNOWN = "UNKNOWN",
}

export enum RouteRole {
  PLACE_OF_ACCEPTANCE = "PLACE_OF_ACCEPTANCE",
  PORT_OF_LOADING = "PORT_OF_LOADING",
  TRANSSHIPMENT_PORT = "TRANSSHIPMENT_PORT",
  PORT_OF_DISCHARGE = "PORT_OF_DISCHARGE",
  PLACE_OF_DELIVERY = "PLACE_OF_DELIVERY",
  PRECARRIAGE_STOP = "PRECARRIAGE_STOP",
  ONCARRIAGE_STOP = "ONCARRIAGE_STOP",
  UNKNOWN = "UNKNOWN",
}

interface RouteAddress {
  companyName: string;
  street: string;
  zip: string;
  city: string;
  countryCode: string;
}

export enum RouteEventFlag {
  SMART_ESTIMATE = "SMART_ESTIMATE",
  INITIAL_ESTIMATE = "INITIAL_ESTIMATE",
  CONFIRMED_ESTIMATE = "CONFIRMED_ESTIMATE",
}

export interface RouteEvent {
  code: EventCode;
  planned: string;
  estimated: string;
  actual: string;
  flags: RouteEventFlag[];
}

export interface RouteLocation {
  type?: RouteType;
  role?: RouteRole;
  countryCode?: string;
  unloc?: string;
  name?: string;
  address?: RouteAddress;
  events?: RouteEvent[];
}

export interface RouteLeg {
  transportMode: ShipmentMode;
  vesselName: string;
  flightVoyageNumber: string;
}

export interface RouteElement {
  location?: RouteLocation;
  leg?: RouteLeg;
}

interface DelayInfoTarget {
  code: string;
  date: string;
}

interface DelayInfo {
  target: DelayInfoTarget;
}

export enum SourceSystemNamesEnum {
  CW = "CW",
  MG = "MG",
  ACT = "ACT",
}

export interface ShipmentDetails {
  id: string;
  housebill: string;
  favorite?: boolean;
  masterbills?: string[];
  references?: ShipmentDetailsReferences;
  parties?: Party[];
  transportMode: ShipmentMode;
  productName?: ShipmentProducts;
  productServiceElements?: ProductServiceElements[];
  incoterms?: Incoterms;
  goodsDescriptions?: string[];
  measures?: Measures;
  transportUnits?: TransportUnitDetails[];
  transitStatus?: ShipmentTransitStatus;
  delayStatus?: DelayStatus;
  delayDays?: number;
  delayInfo?: DelayInfo;
  emissions?: Emissions;
  events?: Event[];
  placeAcceptance?: ShipmentLocation;
  placeDelivery?: ShipmentLocation;
  route?: RouteElement[];
  shipperCompanyName?: string;
  consigneeCompanyName?: string;
  customerCompanyName?: string;
  carrierCompanyNames?: string[];
  sourceSystemName?: SourceSystemNamesEnum;
}

export enum CustomsDeclarationType {
  EXPORT_CUSTOMS = "EXPORT_CUSTOMS",
  IMPORT_CUSTOMS = "IMPORT_CUSTOMS",
}

export enum BrokerageType {
  STANDALONE = "STANDALONE",
  TRANSPORT_RELATED = "TRANSPORT_RELATED",
}

export interface MovementReferenceNumber {
  movementReferenceNumber: string;
  lineItems: number;
}

interface CustomsDeclaration {
  type: CustomsDeclarationType;
  lastEvent?: ShipmentEvent;
  movementReferenceNumbers?: MovementReferenceNumber[];
  submittedInCountry: string;
  brokerageType: BrokerageType;
  importerOfRecord: string;
  exporterOfRecord: string;
}

export interface ShipmentCustomsDeclarations {
  customsDeclarations?: CustomsDeclaration[];
}

export interface MapItem {
  type: string;
}

enum PathImportance {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export enum PathRealness {
  ACTUAL = "ACTUAL",
  PREDICTED = "PREDICTED",
  PREVIOUSLY_PREDICTED = "PREVIOUSLY_PREDICTED",
}

interface Coordinates {
  longitude: number;
  latitude: number;
}

export interface Path extends MapItem {
  importance: PathImportance;
  realness: PathRealness;
  pathCoordinates?: Coordinates[];
}

export enum PointSubType {
  CITY = "CITY",
  PORT = "PORT",
  AIRPORT = "AIRPORT",
  RAILSTATION = "RAILSTATION",
  ROADTERMINAL = "ROADTERMINAL",
  VESSEL = "VESSEL",
  AIRCRAFT = "AIRCRAFT",
  TRAIN = "TRAIN",
  TRUCK = "TRUCK",
}

export interface Point extends MapItem {
  subtype: PointSubType;
  name: string;
  countryCode: string;
  unloc: string;
  pointCoordinates?: Coordinates;
  isCurrent: boolean;
}

export interface ShipmentMap {
  items: Array<MapItem | Path | Point>;
}

export interface ShipmentsNumberByShipmentMode {
  transportMode: ShipmentMode;
  total: number;
}

export interface ShipmentsByCurrentPositionRequestBody {
  transitStatus?: ShipmentTransitStatus[];
  transportMode?: ShipmentMode[];
}

export enum CurrentPositionType {
  AIRCRAFT = "AIRCRAFT",
  AIRPORT = "AIRPORT",
  CITY = "CITY",
  PORT = "PORT",
  RAILSTATION = "RAILSTATION",
  ROADTERMINAL = "ROADTERMINAL",
  STATION = "STATION",
  TRAIN = "TRAIN",
  TRANSSHIPMENT_PORT = "TRANSSHIPMENT_PORT",
  TRUCK = "TRUCK",
  UNKNOWN = "UNKNOWN",
  VESSEL = "VESSEL",
}

export interface ShipmentsCurrentPosition {
  type: CurrentPositionType;
  name: string;
  key: string;
  coordinates?: Coordinates;
}

export interface ShipmentsByCurrentPosition {
  currentPosition: ShipmentsCurrentPosition;
  total: number;
  delayed: number;
}

export interface ShipmentsColumnSuggestion {
  value: string;
  count: number;
}

export enum Role {
  SHIPPER_COMPANY_NAME = "SHIPPER_COMPANY_NAME",
  CONSIGNEE_COMPANY_NAME = "CONSIGNEE_COMPANY_NAME",
  CUSTOMER_COMPANY_NAME = "CUSTOMER_COMPANY_NAME",
}

export enum FilterRole {
  SHIPPER_COMPANY_NAME = "shipperCompanyName",
  CONSIGNEE_COMPANY_NAME = "consigneeCompanyName",
  CUSTOMER_COMPANY_NAME = "customerCompanyName",
}
